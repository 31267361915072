/* eslint-disable-next-line import/named */
import { compact } from 'lodash'
import { type Offer } from '~/types/offers'
import { type OptionPayload } from '~/types/options'

export const optionsPayload = (
  offer: Offer,
  optionsPayload: { [key: string]: string }[]
) => {
  const options = offer?.options

  if (!options) return []

  return compact(
    options
      .filter((option) => option.optionId !== 'GarantieRachat')
      .map((option) => {
        const matchedPayload = optionsPayload.find(
          (payload) =>
            payload.marketingProductId === offer.marketingProductId &&
            payload.levelCode === offer.levelCode &&
            payload.optionId === option.optionId &&
            (option.optionLevel
              ? payload.optionLevel === option.optionLevel
              : true)
        )

        if (!matchedPayload) return undefined

        return {
          ...matchedPayload,
          price: option?.optionPrice || 0,
          group: 1,
        } as OptionPayload
      })
  )
}
