import { render, staticRenderFns } from "./_orderId.vue?vue&type=template&id=8d09192a"
import script from "./_orderId.vue?vue&type=script&setup=true&lang=ts"
export * from "./_orderId.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./_orderId.vue?vue&type=style&index=0&id=8d09192a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OffersTitle: require('/vercel/path0/components/OffersTitle.vue').default,MoleculesNotificationCard: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/molecules/notification-card/notification-card.vue').default,FoundationsUiIcon: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/foundations/ui-icon/ui-icon.vue').default,Recap: require('/vercel/path0/components/Recap.vue').default,MoleculesCrossSellCard: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/molecules/cross-sell-card/cross-sell-card.vue').default,OffersLegalNotice: require('/vercel/path0/components/OffersLegalNotice.vue').default})
