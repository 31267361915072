/* eslint-disable-next-line import/named */
import { compact } from 'lodash'
import { type Offer } from '~/types/offers'
import { type OptionPayload } from '~/types/options'

export const limitationRedemptionPayload = (
  offer: Offer,
  optionsPayload: { [key: string]: string }[]
) => {
  const options = offer?.options

  if (!options) return []

  return compact(
    options
      .filter((option) => option.optionId === 'GarantieRachat')
      .map((option) => {
        const matchedPayload = optionsPayload.find(
          (payload) =>
            payload.marketingProductId === offer.marketingProductId &&
            payload.levelCode === offer.levelCode &&
            payload.optionId === option.optionId
        )

        if (!matchedPayload) return undefined

        return {
          ...matchedPayload,
          group: 0,
          price: 0,
        } as OptionPayload
      })
  )
}
